import React, { useState, useEffect } from 'react';
import Video from 'twilio-video';
import Participant from './Participant';
import { Text, Stack, Box, Button, InputGroup, Input, InputRightElement, Flex } from "@chakra-ui/core";

const Room = ({ roomName, token, handleLogout, presentation_url, handleUpdateRoom }) => {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [localScreenTracks, setLocalScreenTracks] = useState([]);
  const [localTwilioTracks, setLocalTwilioTracks] = useState([]);
  const [localPresentationURL, setLocalPresentationURL] = useState(null);

  useEffect(() => {
    console.log("LOADING");
    const participantConnected = participant => {
      setParticipants(prevParticipants => [...prevParticipants, participant]);
    };

    const participantDisconnected = participant => {
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant)
      );
    };

    Video.connect(token, {
      name: roomName
    }).then(room => {
      setRoom(room);
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.participants.forEach(participantConnected);
    });



    return () => {
      setRoom(currentRoom => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach(function(trackPublication) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  const remoteParticipants = participants.map(participant => (
    <Participant key={participant.sid} participant={participant} />
  ));

  async function handleShareScreen() {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true });
      const tracks = stream.getTracks();
      setLocalScreenTracks(tracks);

      const videoTrack = tracks.filter(t => t.kind === "video").pop();
      const audioTrack = tracks.filter(t => t.kind === "audio").pop();

      const twilioTracks = [];
      if(videoTrack) {
        const screenVideoTrack = new Video.LocalVideoTrack(videoTrack);
        twilioTracks.push(screenVideoTrack);
      }

      if(audioTrack) {
        const screenAudioTrack = new Video.LocalAudioTrack(audioTrack);
        twilioTracks.push(screenAudioTrack);
      }
      
      setLocalTwilioTracks(twilioTracks);
      twilioTracks.forEach(t => room.localParticipant.publishTrack(t));
    } catch(err) {
      console.log("Could not share screen: ", err);
    }
  }

  function handleStopShareScreen() {
    localTwilioTracks.forEach(t => t.stop());
    localScreenTracks.forEach(t => t.stop());

    setLocalScreenTracks([]);
    setLocalTwilioTracks([]);
  }

  function logout() {
    handleStopShareScreen();
    if(room) room.disconnect();

    handleLogout();
  }

  function presentationURLDidChange(e) {
    const url = e.target.value;
    setLocalPresentationURL(url);
  }

  return (
    <div className="room">
      <h2>Room: {roomName}</h2>
      <Stack spacing={10}>
      <Flex>
        <Button color="black" backgroundColor="red.100" onClick={logout}>Leave Call</Button>
        {(room && localTwilioTracks.length === 0) && <Button ml={2} color="black" backgroundColor="blue.100" onClick={handleShareScreen}>Share Screen</Button> }
        {(room && localTwilioTracks.length > 0) && <Button ml={2} color="black" backgroundColor="blue.100" onClick={handleStopShareScreen}>Stop Sharing Screen</Button> }
        {(room) && 
        <InputGroup ml={2} h="3rem" size="md">
          <Input
            pr="4.5rem"
            type="text"
            color="black"
            placeholder="Presentation URL"
            onChange={presentationURLDidChange}
            value={localPresentationURL || presentation_url}
          />
          {(localPresentationURL && localPresentationURL.length > 0) && <InputRightElement width="4.5rem">
            <Button h="1.75rem" mr={2} size="sm" backgroundColor="purple.100" color="black" onClick={() => handleUpdateRoom(localPresentationURL)}>
              Update
            </Button>
          </InputRightElement>}
        </InputGroup> }
      </Flex>
      
      <Box className="local-participant">
        {room ? (
          <Flex alignItems="center">
            <Box p={1}>
            <Participant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
          />
            </Box>
          
          <Box p={1}>
            <iframe src={presentation_url} allow="autoplay" title="My Presentation"></iframe>
          </Box>
          </Flex>
          
        ) : (
          ''
        )}
      </Box>
      <Box>
        <Text fontSize="lg" fontWeight="medium">Your Friends 👇</Text>
        <div className="remote-participants">{remoteParticipants}</div>
      </Box>
      </Stack>
      
      
    </div>
  );
};

export default Room;
