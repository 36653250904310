import React, { useState, useEffect } from 'react';
import { ThemeProvider, CSSReset, Text, Stack, Box, Button, Flex } from "@chakra-ui/core";
import './App.css';
import actions from './actions';
import Room from './Room';

function App() {
  const [loading, setLoading] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [token, setToken] = useState(null);
  const [presentation_url, setPresentationURL] = useState(null);

  useEffect(() => {

    async function fetchData() {
      await fetchRooms();
    }

    fetchData();   
  }, []);

  async function fetchRooms() {
    setLoading(true)
    const rooms = await actions.fetchRooms();
    if(rooms) setRooms(rooms);
    setLoading(false);
  }
  
  async function onRoomClick(name) {
    const { token, presentation_url } = await actions.connectToRoom(name)
    setToken(token);
    setCurrentRoom(name);
    setPresentationURL(presentation_url);
    console.log(token);
  }

  async function handleUpdateRoom(presentation_url) {
    try {
      await actions.updateRoom(currentRoom, presentation_url)
      setPresentationURL(presentation_url);
    } catch(err) {

    }

  }

  function logout(e) {
    setToken(null)
    setCurrentRoom(null)
    setPresentationURL(null);
  }

  function renderRoom(room = {}) {
    return (
      <Box key={room.id} maxW="md" minW="sm" borderWidth="1px" rounded="lg" overflow="hidden">
        <Box p="6">
          <Flex justify="space-between">
            <Box>
              <Box
                mt="1"
                fontWeight="semibold"
                as="h4"
                lineHeight="tight"
                isTruncated
              >
                <Text fontSize="xl">{room.name}</Text>
              </Box>

              <Box>
                <Text fontSize="xs">{room.date}</Text>
              </Box>
            </Box>
            <Box d="flex" mt="2" alignItems="center">
              <Button backgroundColor="cyan.100" color="black" onClick={() => onRoomClick(room.name)}>Join</Button>
            </Box>
          </Flex>          
        </Box>
    </Box>
    )
  }

  return (
    <ThemeProvider>
      <CSSReset />
      <div className="App purpleBackground">
        <Stack p={20} spacing={10} align="center">
        <Text fontSize="2xl" fontWeight="bold">Zip Rooms 🚀</Text>
          {loading && <Text>Looking for rooms... 🕵️‍♂️</Text>}
          {(rooms.length === 0 && !loading) && 
            <Flex align="center" flexDir="column">
              <Text fontSize="lg">no rooms yet 🙃</Text>
              <Button mt={2} backgroundColor="cyan.100" color="black" onClick={fetchRooms}>Refresh</Button>
            </Flex>
          }
          {!currentRoom && 
            <div>
              <Box mt={2}>
                {rooms.map(renderRoom)}
              </Box>
            </div>
          }

          {(currentRoom && token) && <Room roomName={currentRoom} presentation_url={presentation_url} token={token} handleLogout={logout} handleUpdateRoom={handleUpdateRoom} />}          
        </Stack>
      </div>
    </ThemeProvider>
  );
}

export default App;
