import axios from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

async function fetchRooms() {
    try {
        const { data = {} } = await axios.get(`${API_ENDPOINT}/rooms`);
        const { rooms } = data;
        return rooms;
    } catch(err) {
        const errData = err.response ? err.response.data : err;
        return { err: errData };
    }
}

async function connectToRoom(name) {
    try {
        const id = getUUID();
        const { data = {} } = await axios.get(`${API_ENDPOINT}/rooms/${encodeURIComponent(name)}`, { headers: { 'zip-identity': id } });
        return data;
    } catch(err) {
        const errData = err.response ? err.response.data : err;
        return { err: errData };
    }
}

async function updateRoom(name, presentation_url) {
    try {
        const id = getUUID();
        const { data = {} } = await axios.put(`${API_ENDPOINT}/rooms/${encodeURIComponent(name)}`, { presentation_url }, { headers: { 'zip-identity': id } });
        console.log(data);
        return data;
    } catch(err) {
        const errData = err.response ? err.response.data : err;
        return { err: errData };
    }
}

function getUUID() {
    const prevUUID = localStorage.getItem('UUID');
    var UUID = '';
    if (prevUUID) {
        UUID = prevUUID;
    } else {
        UUID = createUUID();
        localStorage.setItem('UUID', UUID);
    }

    return UUID;
}

function createUUID(a) {
    // See: https://github.com/amplitude/Amplitude-Javascript/blob/master/src/uuid.js
    var uuid = function(a) {
        return a           // if the placeholder was passed, return
            ? (              // a random number from 0 to 15
                a ^            // unless b is 8,
                Math.random()  // in which case
                * 16           // a random number from
                >> a / 4         // 8 to 11
            ).toString(16) // in hexadecimal
            : (              // or otherwise a concatenated string:
                [1e7] +        // 10000000 +
                -1e3 +         // -1000 +
                -4e3 +         // -4000 +
                -8e3 +         // -80000000 +
                -1e11          // -100000000000,
            ).replace(     // replacing
                /[018]/g,    // zeroes, ones, and eights with
                uuid         // random hex digits
            );
    }

    return uuid();
}

export default {
    fetchRooms,
    connectToRoom,
    updateRoom,
    getUUID
}